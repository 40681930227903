import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />n
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >

<path d="M715 3163 c-22 -2 -47 -7 -55 -10 -18 -8 -17 -7 -57 -17 -18 -4 -33
-11 -33 -14 0 -4 -11 -8 -25 -8 -40 -3 -169 -82 -234 -144 -66 -63 -141 -173
-164 -240 -22 -67 -41 -132 -43 -155 -1 -11 -5 -40 -9 -65 -10 -64 36 -287 56
-274 5 3 6 -1 3 -9 -6 -16 26 -84 63 -134 12 -17 23 -34 23 -36 0 -20 218
-197 243 -197 3 0 22 -8 43 -17 22 -9 44 -17 49 -18 6 -1 24 -7 40 -15 35 -16
278 -21 310 -6 11 5 31 11 44 12 13 2 29 8 35 15 8 8 6 10 -9 6 -11 -3 -52
-11 -90 -17 -189 -33 -425 70 -562 245 -91 116 -125 220 -124 384 1 120 3 150
13 166 3 6 9 26 13 45 11 51 57 137 108 200 82 103 209 183 342 214 66 15 234
24 252 13 5 -3 30 -8 56 -11 26 -3 61 -13 78 -22 17 -8 37 -13 45 -10 8 3 12
1 9 -4 -3 -5 16 -20 42 -34 26 -13 62 -37 79 -53 64 -59 66 -52 2 11 -37 36
-86 77 -109 91 -40 26 -172 82 -213 91 -46 11 -142 24 -161 22 -11 -1 -38 -3
-60 -5z m257 -50 c-6 -2 -9 -10 -6 -15 4 -7 2 -8 -5 -4 -13 9 -5 26 12 25 9 0
8 -2 -1 -6z"/>
<path d="M855 3025 c113 -19 168 -37 241 -81 121 -73 215 -188 259 -318 74
-220 22 -439 -144 -607 -42 -42 -67 -72 -56 -67 11 6 52 43 90 82 191 194 225
477 85 716 -100 169 -275 273 -479 285 l-86 5 90 -15z"/>
<path d="M718 3023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1041 2923 c49 -24 95 -58 139 -102 264 -265 186 -704 -155 -871 -33
-16 -54 -30 -46 -30 8 0 48 16 89 36 176 85 302 286 302 484 0 197 -123 396
-299 483 -40 20 -80 37 -89 37 -8 0 18 -17 59 -37z"/>
<path d="M718 2896 c-54 -15 -128 -62 -128 -82 0 -21 15 -17 67 19 44 29 54
32 128 32 82 0 106 -9 162 -58 21 -19 23 -19 29 -2 8 21 -20 47 -81 75 -56 26
-122 32 -177 16z"/>
<path d="M726 2833 c-33 -9 -96 -55 -96 -69 0 -20 14 -17 56 11 66 45 132 45
199 -1 41 -27 55 -30 55 -10 0 7 -18 26 -41 42 -31 21 -54 28 -97 30 -31 2
-66 0 -76 -3z"/>
<path d="M1168 2777 c18 -21 46 -63 63 -93 34 -59 55 -80 39 -39 -15 38 -63
105 -101 140 l-33 30 32 -38z"/>
<path d="M738 2770 c-39 -12 -75 -47 -61 -61 8 -8 19 -5 41 11 25 18 39 22 77
18 25 -3 55 -14 66 -24 14 -13 23 -14 30 -7 16 16 2 32 -45 54 -44 20 -66 22
-108 9z"/>
<path d="M748 2705 c-16 -9 -28 -22 -28 -30 0 -17 26 -20 35 -5 3 6 17 10 30
10 13 0 27 -4 30 -10 9 -15 35 -12 35 4 0 34 -62 53 -102 31z"/>
<path d="M773 2653 c-7 -2 -13 -14 -13 -25 0 -14 6 -19 23 -16 14 2 22 10 22
22 0 21 -12 28 -32 19z"/>
<path d="M1508 2654 c-20 -6 -20 -11 -20 -213 0 -62 5 -118 10 -123 7 -7 45
-8 102 -3 76 6 96 11 121 32 23 20 29 33 29 63 0 32 -6 44 -35 68 -33 26 -34
30 -19 43 36 30 33 98 -5 118 -19 11 -159 22 -183 15z m125 -21 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m21 -29 c21 -20 21 -70 0 -78 -9 -3
-36 -6 -60 -6 l-44 0 0 40 c0 25 -5 41 -12 42 -11 1 38 10 89 16 6 1 19 -5 27
-14z m24 -146 c6 -6 12 -25 12 -44 0 -43 -24 -56 -93 -52 l-52 3 -1 50 c-1 28
0 53 2 56 5 9 118 -2 132 -13z m-1 -124 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z"/>
<path d="M2077 2646 c-54 -20 -74 -41 -93 -98 -38 -116 25 -236 127 -238 15 0
43 13 64 30 21 17 39 30 40 30 2 0 2 -11 0 -25 -2 -21 2 -25 25 -25 22 0 27 4
25 18 -2 9 -4 50 -4 90 l-1 72 -65 0 c-57 0 -65 -2 -65 -19 0 -20 25 -35 41
-25 5 3 18 3 28 -1 16 -5 18 -11 10 -37 -15 -53 -33 -66 -84 -58 -56 8 -72 22
-86 72 -18 68 1 147 41 168 37 20 112 7 131 -22 4 -6 15 -5 27 3 28 18 14 42
-38 63 -48 19 -73 20 -123 2z"/>
<path d="M2383 2653 c-23 -4 -23 -6 -23 -168 l0 -164 43 -6 c23 -4 56 -4 72
-2 17 3 43 6 58 6 41 1 87 50 87 93 -1 32 -29 78 -48 78 -5 0 -12 9 -15 20 -3
11 -1 18 3 15 5 -3 17 6 26 20 20 31 12 69 -19 89 -23 15 -145 28 -184 19z
m124 -19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m34 -48 c8
-17 8 -29 -1 -45 -10 -19 -18 -22 -68 -19 l-56 3 -2 43 -2 42 59 0 c53 0 61
-3 70 -24z m14 -118 c6 -1 9 -22 7 -50 -4 -44 -6 -49 -35 -56 -17 -4 -49 -5
-71 -2 l-41 5 0 50 c0 28 4 53 8 56 7 5 72 4 132 -3z"/>
<path d="M2795 2649 c-74 -21 -112 -93 -101 -191 10 -102 73 -157 164 -144 99
13 149 79 140 184 -8 80 -30 119 -81 143 -45 20 -75 22 -122 8z m114 -58 c20
-15 25 -30 29 -80 5 -73 -1 -99 -28 -129 -24 -25 -79 -30 -120 -9 -42 21 -58
108 -34 190 13 43 110 61 153 28z"/>
<path d="M3133 2649 c-40 -12 -63 -44 -63 -88 0 -48 22 -68 101 -91 42 -13 73
-28 79 -40 11 -20 4 -50 -14 -62 -20 -14 -71 -8 -110 12 -35 17 -40 18 -54 3
-15 -14 -14 -18 12 -38 37 -29 103 -40 154 -24 86 25 97 137 17 177 -11 5 -44
15 -73 22 -43 10 -53 17 -59 38 -11 46 59 69 122 40 22 -10 28 -9 36 3 13 21
11 27 -11 39 -29 15 -101 21 -137 9z"/>
<path d="M3450 2653 c-39 -7 -80 -52 -80 -87 1 -52 44 -89 118 -101 88 -14 94
-104 7 -105 -17 0 -48 9 -69 20 -33 16 -40 17 -52 5 -19 -19 -19 -21 22 -45
39 -25 124 -36 124 -16 0 7 3 7 8 -2 7 -9 16 -7 42 10 39 27 58 78 41 115 -14
30 -71 64 -95 57 -11 -4 -16 -2 -12 4 3 5 -11 12 -33 16 -25 4 -40 12 -43 23
-12 53 26 74 100 54 36 -9 46 -9 58 3 20 20 19 22 -26 40 -40 16 -61 18 -110
9z"/>
<path d="M3706 2650 c-10 -4 -13 -38 -14 -158 0 -85 2 -161 5 -169 8 -20 154
-9 202 15 59 29 76 63 76 147 0 81 -16 115 -71 147 -29 16 -168 29 -198 18z
m37 -17 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m90 0 c-13 -2
-35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m38 -38 c16 -9 35 -28 40 -43
13 -32 17 -122 7 -122 -5 0 -8 -6 -8 -13 0 -28 -39 -49 -100 -54 l-60 -5 0
119 c0 66 3 123 7 126 12 13 84 7 114 -8z"/>
<path d="M4470 2651 c-75 -5 -95 -9 -95 -21 0 -11 14 -16 53 -18 l52 -3 0
-135 c0 -100 3 -134 13 -134 8 0 7 -4 -3 -10 -11 -7 -8 -10 16 -10 l31 0 2
137 c1 82 7 140 13 144 6 4 5 8 -3 11 -8 3 10 3 39 0 51 -4 52 -4 52 22 0 29
17 27 -170 17z m53 -18 c-24 -2 -62 -2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43
-4z"/>
<path d="M4819 2652 c-24 -2 -39 -9 -42 -20 -3 -9 -25 -75 -50 -147 -65 -186
-62 -172 -28 -167 24 3 31 10 38 41 l9 36 76 3 c75 3 75 3 81 -25 9 -39 18
-49 50 -57 26 -6 27 -5 21 21 -4 16 -27 82 -51 147 -23 66 -43 126 -43 133 0
7 -4 13 -9 13 -5 0 -11 6 -13 13 -2 7 -17 11 -39 9z m24 -19 c-7 -2 -21 -2
-30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m13 -113 c12 -38 18 -70 13 -71 -5 0
-27 -1 -49 -1 -62 0 -61 0 -49 25 7 12 15 29 19 37 11 23 24 83 21 95 -1 6 3
5 10 -2 7 -7 22 -44 35 -83z"/>
<path d="M1830 2485 l0 -165 29 0 29 0 0 165 0 165 -29 0 -29 0 0 -165z"/>
<path d="M4131 2595 c-12 -31 -21 -58 -21 -61 0 -3 -6 -20 -14 -37 -16 -36
-44 -123 -51 -155 -3 -17 1 -22 19 -22 17 0 27 10 39 40 l15 40 71 0 71 0 16
-39 c13 -34 48 -60 62 -46 9 9 -95 307 -110 316 -6 4 -5 9 3 12 8 3 -7 6 -33
6 l-47 1 -20 -55z m88 -73 c12 -35 19 -67 16 -72 -3 -5 -18 -7 -33 -5 -15 2
-37 4 -48 4 l-22 1 25 76 c14 41 29 72 34 67 4 -4 17 -36 28 -71z m-6 -109
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M360 2593 c0 -3 7 -14 16 -24 15 -16 13 -30 -16 -171 -18 -84 -35
-159 -37 -165 -4 -10 20 -13 102 -13 105 0 106 0 101 -22 -3 -13 -3 -17 0 -9
5 11 8 11 16 0 6 -10 7 -7 3 9 -4 13 -2 22 4 22 6 0 11 -8 12 -17 0 -10 12 27
25 81 19 79 22 101 12 108 -10 6 -9 11 5 22 9 7 22 38 28 68 11 52 10 58 -12
87 l-23 31 -118 0 c-65 0 -118 -3 -118 -7z m196 -79 c-3 -9 -6 -19 -6 -24 0
-5 -18 -19 -40 -31 -40 -23 -45 -34 -33 -77 4 -17 11 -22 24 -19 21 6 24 -8 9
-47 -8 -21 -16 -26 -46 -26 -35 0 -36 1 -28 28 4 15 8 48 9 74 1 26 6 53 12
60 6 7 14 28 18 46 6 30 10 32 47 32 32 0 39 -3 34 -16z"/>
<path d="M676 2574 c16 -24 16 -30 -1 -107 -10 -45 -19 -89 -19 -97 -1 -9 -5
-20 -8 -25 -5 -9 -28 -118 -28 -136 0 -4 8 -10 18 -14 14 -5 15 -4 5 6 -21 21
-15 28 19 23 19 -2 35 2 40 9 5 7 8 -2 9 -23 0 -19 3 -29 6 -22 2 6 8 10 13 7
4 -3 6 5 2 17 -5 21 -4 22 12 9 10 -9 20 -11 24 -5 12 16 32 15 39 -2 4 -12 9
-10 25 13 l19 28 -6 -30 c-6 -27 -5 -28 6 -10 7 11 15 22 19 25 4 3 12 33 19
68 10 51 10 66 0 79 -11 12 -10 17 4 28 19 14 37 65 37 103 0 13 -9 37 -21 53
l-20 29 -115 0 -114 0 16 -26z m179 -45 c14 -15 -8 -59 -36 -71 -16 -7 -29
-17 -29 -21 0 -5 -4 -5 -10 -2 -11 7 -13 -9 -4 -42 4 -14 13 -22 24 -20 22 2
23 -2 14 -48 -4 -25 -12 -35 -25 -36 -64 -4 -64 -4 -62 21 2 14 4 50 6 81 1
31 8 63 15 71 6 8 13 25 14 39 2 13 8 26 15 29 17 7 70 6 78 -1z m-27 -280
c-3 -8 -7 -3 -11 10 -4 17 -3 21 5 13 5 -5 8 -16 6 -23z"/>
<path d="M968 2573 l20 -28 -34 -150 c-39 -174 -41 -187 -17 -178 13 5 15 2
10 -11 -4 -9 -3 -15 2 -11 5 3 12 -1 15 -7 3 -7 3 -3 0 10 -5 21 -2 22 45 22
39 0 51 -4 51 -15 0 -17 25 -20 35 -5 3 6 -1 7 -9 4 -9 -3 -16 -1 -16 5 0 6
19 11 44 11 52 0 56 4 76 84 l16 60 34 -31 35 -31 -33 -68 c-147 -298 -525
-364 -774 -136 -45 42 -56 48 -40 25 89 -126 289 -206 456 -182 277 39 474
304 425 571 l-14 73 -3 -112 c-1 -62 -6 -113 -10 -113 -4 1 -20 11 -36 25
l-29 23 12 69 c7 37 14 79 15 93 l1 25 -148 3 -149 3 20 -28z m172 -63 c0 -25
4 -25 43 1 27 17 26 15 -8 -17 -24 -22 -32 -34 -20 -30 11 5 27 10 35 14 8 3
3 -5 -12 -17 -22 -17 -32 -20 -47 -12 -16 9 -21 7 -26 -9 -3 -10 -2 -27 3 -37
6 -11 5 -35 -2 -63 -10 -41 -14 -45 -43 -48 -40 -4 -39 -6 -13 118 27 124 25
120 60 120 23 0 30 -4 30 -20z m104 -141 c22 -10 42 -37 34 -45 -2 -1 -18 11
-36 27 -36 32 -35 35 2 18z"/>
<path d="M2332 2229 l3 -74 32 -2 c37 -3 63 18 63 52 0 30 -28 58 -51 52 -12
-3 -19 1 -21 16 -2 11 -9 22 -16 25 -10 3 -12 -13 -10 -69z m68 -4 c16 -19 3
-55 -21 -55 -14 0 -19 7 -19 28 0 43 17 54 40 27z"/>
<path d="M2516 2293 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M2790 2227 l0 -73 35 -1 c39 -1 58 16 58 52 0 36 -22 57 -50 50 -19
-4 -23 -2 -23 20 0 14 -4 25 -10 25 -6 0 -10 -31 -10 -73z m65 -23 c0 -19 -6
-30 -17 -32 -21 -4 -34 34 -17 54 18 22 34 11 34 -22z"/>
<path d="M3518 2293 c0 -5 -2 -15 -2 -23 -1 -10 -10 -14 -28 -12 -36 4 -57
-34 -42 -76 8 -23 15 -27 52 -28 l42 -1 0 74 c0 42 -4 73 -10 73 -5 0 -11 -3
-12 -7z m-13 -89 c0 -19 -6 -30 -16 -32 -21 -4 -32 24 -18 49 14 28 34 18 34
-17z"/>
<path d="M3790 2265 c-6 -8 -8 -20 -4 -27 4 -7 10 -28 14 -48 4 -26 11 -36 28
-38 25 -4 30 13 7 22 -9 3 -15 18 -15 36 0 20 5 30 15 30 8 0 15 5 15 10 0 6
-7 10 -15 10 -8 0 -15 5 -15 10 0 15 -16 12 -30 -5z"/>
<path d="M2670 2263 c-36 -7 -44 -18 -45 -66 -1 -23 -4 -50 -9 -59 -6 -13 -2
-19 18 -24 14 -3 38 -3 52 0 35 9 30 49 -6 54 -33 5 -51 22 -23 22 27 0 41 18
41 53 0 15 -1 26 -2 26 0 -1 -12 -4 -26 -6z m2 -38 c0 -5 -5 -11 -11 -13 -6
-2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m8 -85 c0 -5 -9 -10 -20
-10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z"/>
<path d="M1601 2233 c3 -78 35 -105 52 -43 l10 35 10 -37 c12 -51 32 -48 46 6
13 53 14 66 3 66 -5 0 -13 -17 -18 -37 l-10 -38 -10 34 c-13 45 -30 50 -39 12
-8 -37 -25 -41 -25 -7 0 14 -4 28 -10 31 -6 4 -10 -6 -9 -22z"/>
<path d="M1803 2244 c3 -11 9 -36 13 -56 8 -46 32 -50 41 -8 8 43 20 45 27 5
9 -46 32 -45 40 3 4 20 9 45 12 55 3 9 1 17 -4 17 -6 0 -14 -17 -19 -37 l-9
-38 -13 38 c-15 45 -29 48 -37 7 -8 -37 -22 -39 -26 -3 -2 14 -9 28 -17 31 -9
3 -11 -2 -8 -14z"/>
<path d="M2014 2223 c15 -84 33 -95 49 -33 6 26 11 30 18 19 5 -8 9 -22 9 -31
0 -20 19 -34 30 -22 14 15 24 104 11 104 -6 0 -11 -13 -11 -29 0 -43 -17 -47
-24 -6 -9 46 -30 45 -42 -2 l-10 -38 -8 38 c-5 20 -13 37 -18 37 -6 0 -8 -15
-4 -37z"/>
<path d="M2514 2246 c-9 -22 0 -91 11 -89 14 4 19 103 5 103 -6 0 -13 -6 -16
-14z"/>
<path d="M2974 2235 c-14 -21 -15 -31 -6 -51 15 -31 37 -40 68 -26 19 9 24 19
24 46 0 57 -56 76 -86 31z m60 -18 c8 -12 7 -21 -6 -33 -20 -20 -38 -11 -38
20 0 39 23 46 44 13z"/>
<path d="M3153 2254 c-22 -10 -14 -44 12 -54 14 -5 25 -15 25 -21 0 -7 -9 -9
-25 -5 -14 4 -25 2 -25 -4 0 -12 45 -24 59 -15 6 3 11 17 11 30 0 16 -7 25
-25 29 -29 7 -34 29 -5 24 10 -2 20 2 22 7 3 11 -30 17 -49 9z"/>
<path d="M3303 2254 c-22 -10 -14 -44 12 -54 14 -5 25 -15 25 -21 0 -7 -9 -9
-25 -5 -14 4 -25 2 -25 -4 0 -16 49 -23 65 -10 24 20 18 40 -15 51 -36 13 -40
33 -5 26 16 -3 24 0 22 7 -4 12 -35 18 -54 10z"/>
<path d="M3629 2244 c-12 -15 -10 -16 19 -10 41 10 43 -8 1 -23 -24 -8 -30
-15 -27 -33 3 -20 9 -23 45 -24 l42 -1 -6 46 c-3 25 -11 49 -17 53 -18 13 -43
9 -57 -8z m51 -54 c0 -12 -28 -25 -36 -17 -9 9 6 27 22 27 8 0 14 -5 14 -10z"/>
<path d="M3929 2244 c-11 -13 -9 -15 12 -12 31 4 37 -15 7 -22 -16 -4 -23 -13
-23 -30 0 -22 4 -25 41 -26 l42 -1 -5 41 c-3 23 -6 47 -9 54 -5 17 -49 15 -65
-4z m51 -54 c0 -12 -28 -25 -36 -17 -9 9 6 27 22 27 8 0 14 -5 14 -10z"/>
<path d="M4212 2248 c-17 -17 -15 -73 3 -88 19 -16 65 -5 65 16 0 11 -5 12
-17 6 -26 -13 -43 -4 -43 23 0 25 21 38 45 29 8 -4 15 -2 15 4 0 21 -49 29
-68 10z"/>
<path d="M4378 2249 c-25 -14 -24 -75 1 -89 31 -16 49 -12 67 14 31 48 -20
104 -68 75z m47 -45 c0 -32 -21 -39 -38 -12 -8 12 -7 21 3 32 19 24 35 14 35
-20z"/>
<path d="M4540 2203 c0 -63 18 -72 22 -10 2 29 8 42 18 42 11 0 16 -13 18 -43
2 -28 7 -42 15 -39 7 2 11 16 9 31 -4 27 12 56 29 56 5 0 9 -21 9 -46 0 -53
23 -58 28 -6 6 55 -8 67 -82 68 l-66 0 0 -53z"/>
<path d="M580 2199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M880 2199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2222 2169 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M4090 2165 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M938 1913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
